.users {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
 height: 100%;
 width: 90%;
 overflow-y: auto;
 border-radius: 15px;
 margin-left: 15px;
 //border: 1px solid var(--border-color);
 padding: 10px;
 box-shadow: 0px 0px 10px rgba(105, 105, 245, 0.1);
  &::-webkit-scrollbar{
      display: none;
  }

    .hover-friend.active {
      background-color: #F0F0F0;
      border-radius: 15px;
    }
  
    .hover-friend {
      cursor: pointer;
      &:hover {
        background-color: #C2DCF1; // Hover color remains blue
      }
  
      .user {
        display: flex;
        width: 100%;
        padding: 7px 15px;
  
        .user-image {
          .image {
            margin-right: 8px;
            position: relative;
            img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              border: 1px solid var(--text-color);
            }
  
            .active_icon {
              position: absolute;
              width: 13px;
              height: 13px;
              border-radius: 50%;
              background-color: rgb(43, 228, 43);
              right: -2px;
              bottom: 6px;
              border: 1px solid #9b9797;
            }
          }
        }
  
        .user-name-seen {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
  
          .user-name {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
  
            h4.unseen_message {
              font-weight: 700 !important;
            }
  
            h4 {
              color: var(--text-color);
              font-weight: 300;
            }
  
            .msg-time {
              span.unseen_message {
                font-weight: 700 !important;
              }
  
              span {
                font-size: 12px;
                color: var(--text-color);
              }
            }
          }
  
          .seen-unseen-icon {
            display: flex;
            justify-content: center;
            align-items: center;
  
            img {
              width: 15px;
              height: 15px;
              border-radius: 50%;
            }
  
            .unseen,
            .delivared {
              color: var(--text-color);
            }
  
            .seen-icon {
              width: 15px;
              height: 15px;
              border-radius: 50%;
              background-color: #1e6bbd;
            }
          }
        }
      }
    }
  }
  