.message-send-section {
  display: flex;
  align-items: center;
  padding: 5px;
  overflow: visible;
}

.file {
  display: flex;
  padding: 8px;
  font-size: 20px;
  cursor: pointer;
  position: relative; /* Make sure the dropdown aligns with the icon */
  color: var(--icon-color);
}

.file label {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--icon-color);
}

.file #pic {
  display: none;
}

.add-image {
  position: absolute;
  padding: 4px;
  background: #212d44;
  font-size: 14px;
  bottom: 44px;
  display: none;
  color: var(--text-white);
}

.file:hover .add-image {
  display: block;
}

.message-type {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #dfdfdf;
  border-radius: 15px;
  padding: 5px;
  position: relative;
}

.message-type input {
  flex-grow: 1;
  border: none;
  outline: none;
  background-color: #dfdfdf;
  font-size: 19.2px;
  padding: 5px;
}

.message-type input::placeholder {
  color: black;
  font-weight: 400;
  font-size: 19.2px;
}

.emoji-picker {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 5px;
}

.emoji-dropdown {
  position: absolute;
  bottom: 50px;
  right: 0;
  z-index: 1000;
}

.recording-section {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.record-icon,
.send-icon {
  font-size: 24px;
  cursor: pointer;
  margin-left: 10px;
}

.recording-section .timer {
  font-size: 14px;
  margin-left: 10px;
}

.sound-wave {
  display: none;
}

.attach-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  display: block;
  position: absolute;
  bottom: 40px; /* Adjust this to move the dropdown above the icon */
  right: 0;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 5px;
}

.dropdown-item {
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-item:hover {
  background-color: #ddd;
}

.dropdown-item .fa-icon {
  margin-right: 8px;
}
.message-send-section {
  display: flex;
  align-items: center;
  padding: 5px;
  overflow: visible;
}

.file {
  display: flex;
  padding: 8px;
  font-size: 20px;
  cursor: pointer;
  position: relative;
  color: var(--icon-color);
}

.file-upload {
  position: relative;
}

.file-options {
  position: absolute;
  bottom: 40px;
  left: -10px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.file-option {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}

.file-option:hover {
  background-color: #f1f1f1;
}

.file-icon {
  margin-right: 8px;
  font-size: 18px;
}

.add-image {
  position: absolute;
  padding: 4px;
  background: #212d44;
  font-size: 14px;
  bottom: 44px;
  display: none;
  color: var(--text-white);
}

.file:hover .add-image {
  display: block;
}

.message-type {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #dfdfdf;
  border-radius: 15px;
  padding: 5px;
  position: relative;
}

.message-type input {
  flex-grow: 1;
  border: none;
  outline: none;
  background-color: #dfdfdf;
  font-size: 19.2px;
  padding: 5px;
}

.message-type input::placeholder {
  color: black;
  font-weight: 400;
  font-size: 19.2px;
}

.emoji-picker {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 5px;
}

.emoji-dropdown {
  position: absolute;
  bottom: 50px;
  right: 0;
  z-index: 1000;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.recording-section {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.record-icon,
.send-icon {
  font-size: 24px;
  cursor: pointer;
  margin-left: 10px;
}

.recording-section .timer {
  font-size: 14px;
  margin-left: 10px;
  color: var(--recording-color);
}

