.friend-info {
    padding: 60px 15px;
    display: flex;
    flex-direction: column;
    height: 100%;


    /* Adjusted styling */
    position: absolute;
    top: 10px;
    left: 75%;
    width: 25%; /* Adjust the width as needed */
    background-color: #fff; /* Set background color */
    z-index: 1; /* Ensure FriendInfo is above other content */
     .image-name{
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column;
         .image{
             height: 70px;
             width: 70px;
             border-radius: 50%;
             overflow: hidden;
             img{
                 width: 100%;
                 height: 100%;
             }
             margin-bottom: 5px;
         }
         .active-user{
             color: rgb(27, 238, 27);
             margin-bottom: 3px;
         }
         .name{
             h4{
                 font-weight: 600;
                 color: var(--text-color);
                 margin-bottom: 8px;
             }
         }
     }
     .others{
         display: flex;
         flex-direction: column;
         margin: 10px 0px;
         .custom-chat,.privacy,.media{
             display: flex;
             flex-direction: row;
             justify-content: space-between;
             margin: 4px 0px;
             color: var(--text-color);
             cursor: pointer;
             h3{
                 font-size: 14px;
             }
             label{
                 cursor: pointer;
                 color: var(--text-color);
             }
         }
     }
     .gallery{
         display: grid;
         grid-template-columns: repeat(2,1fr);
         grid-gap: 5px;
         overflow-y: auto;
         display: none;
         &::-webkit-scrollbar{
             display: none;
         }
         img{
             width: 100%;
             height: 100%;
         }
     }
     #gallery{
         display: none;
     }
     #gallery:checked ~ .gallery{
         display: grid;
     }
     .message-search {
        //the left padding is 0 the rest is 10px
        padding: 10px 10px 10px 0px;
        width: 100%;
        input {
          width: 100%;
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 20px;
          outline: none;
          font-size: 14px;
          transition: border-color 0.3s ease;
          &:focus {
            border-color: #6f7071;
          }
        }
      }
      .search-note {
        font-size: 12px;
        color: #888;
        margin-top: 5px;
      }
  
 }