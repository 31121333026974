


.col-3{
    width: 30%;
.left-side{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: start;
    //make the color blue
       background-color: #FFFFFF;

       .top {
           padding-top : 20px;
           display: flex;
           .icons{
                position: relative;
               .image-name{
                     width: 40px;
                     height: 40px;
                    margin-left: 15px;
                     border-radius: 50%;
                     overflow: hidden;
                     cursor: pointer;
                     img{
                         width: 100%;
                         height: 100%;
                         object-fit: cover;
                     }
             }
               .theme_logout.show{
                   transform: scale(1);
               }
               .theme_logout{
                box-shadow: 0px 4px 16.5px #00000040;
                   width: 200px;
                   height: 370%;
                   background-color: white;
                   transform: scale(0);
                   position: absolute;
                   transition:  all .6s;
                   top: 100%;
                   padding: 10px;
                   color: #143D64;
                   z-index: 1;
                   border-radius: 10px;
                   .profile{
                    display: flex;
                    align-items: center;
                    padding : 10px;
                    cursor: pointer;
                    .MdOutlinePerson{
                        font-size: 50px;
                        color: #017aff;
                        background-color: #ffffff;
                    }
                    h3{
                        padding-left: 25px;
                        font-size: 20px;
                        font-weight: 600;
                    }
                   }
                   .dark-mode{
                    display: flex;
                    align-items: center;
                    padding : 10px;
                    cursor: pointer;
                    i{
                        margin-right: 25px;
                    }
                }
                   .logout{
                       display: flex;
                       align-items: center;
                       padding : 10px;
                       cursor: pointer;
                          .FaSignOutAlt{
                            font-size: 20px;
                            color: #017aff;
                            background-color: #ffffff;
                          }
                            h3{
                                padding-left: 25px;
                                font-size: 20px;
                                font-weight: 600;
                            }
                   }
               }
           }
       }

       .friend-search {
           display: flex;
           position: relative;
           padding: 0px 15px;
           align-items: center;
           justify-content: space-between;
           margin-top:20px;
           margin-bottom: 20px;

           .add-friend-icon{
            display: flex;
            border-radius: 50%;
            background-color: #F0F0F0;
            padding:10px;
            cursor: pointer;
        }
           .search {
               display: flex;
               width: calc(100% - 35px); /* Adjust the width to accommodate the "add friend" button */
               height: 40px;
               background-color: #F0F0F0;
               border-radius: 22px;
               border: none;
               margin-left: 5px;
               padding : 10px 20px;
                align-items: center;
                justify-content: space-between;

               svg{
                color : #707991;
               }

               button {
                   border-radius: 50px;
                   border: none;
                   color: var(--text-color);
                   font-size: 20px;
                   background-color: transparent;
               }
               .form-control {
                   width: 100%;
                   border-bottom-right-radius: 50px;
                   border-top-right-radius: 50px;
                   border: none;
                   color: var(--text-color);
                   background-color: #F0F0F0;
                    font-size: 15px;
                    outline: none;
                    margin-left: 10px;
                   &::placeholder {
                       color: var(--text-color);
                       opacity: 0.5;
                   }
               }
           }

           .add-friend {
            width: calc(20%);
            height: calc(50%);
            padding-left: 5%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
         i {
            font-size: 42px; // Adjust the font size to make the icon bigger
            color: #143D64; // Optionally, you can change the color of the icon
            border-radius: 50%; // Makes the shape circular
            // make the color beside  the + green
            background-color: #b9d6fa;
        
        }
    }
       }
}

}