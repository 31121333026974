.active-friends{
    display: flex;
    align-items: center;
     .active-friend{
         overflow: hidden;
         .image-active-icon{
             overflow-x: scroll;
             display: flex;
             margin-left: 15px;
             align-items: center;
             &::-webkit-scrollbar{
                 overflow: hidden;
             }
             .image{
                 margin: 5px;
                 position: relative;
                 img{
                     width: 38px;
                     height: 38px;
                     border-radius: 50%;
                 }
                 .active-icon{
                     position: absolute;
                     bottom: 3px;
                     right: 3px;
                     width: 10px;
                     height: 10px;
                     border-radius: 50%;
                     background-color: rgb(27, 223, 53);
                 }
             }
         }
     }
 }