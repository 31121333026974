.send-email {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
  
    .send-email-container {
      background-color: #C2DCF1;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Box shadow for a subtle depth effect */
  
      .send-email-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
  
        h3 {
          font-size: 18px;
          font-weight: bold;
          margin: 0;
        }
  
        button {
          border: none;
          background-color: transparent;
          cursor: pointer;
          font-size: 16px;
          color: #666;
        }
      }
  
      form {
        display: flex;
        flex-direction: column;
  
        input {
          padding: 10px;
          margin-bottom: 15px;
          border: 1px solid #ccc;
          border-radius: 5px;
          font-size: 16px;
        }
  
        button[type='submit'] {
          padding: 10px 20px;
          background-color: #143D64; /* Blue color for the send button */
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-size: 16px;
          transition: background-color 0.3s ease;
  
          &:hover {
            background-color: #C2DCF1; /* Darker shade of blue on hover */
          }
        }
      }
    }
  }
  