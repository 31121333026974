.messenger {
    width: 100%;
    height: 100vh;
    background-color: white;
  
    .row {
      height: 100%;
      .col-9 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #f4f8fc;

        .offer-to-add-friend{
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background-color: white;
          border-radius: 20px;
          padding: 10px;
          margin: 20px;
          width :385px;
          height: 180px;

          .add-friend{
            padding:10px;
            
        p {
            font-size: 16px;
            font-weight: 600;
            color: #333;
            text-align: center;
            margin: 0;
            padding: 10px;
            border-radius: 5px;
            margin-bottom: 10px;
        }
        .invite{
          padding: 20px;
          border-radius: 100px;
          background-color: #C2DCF1;
        a {
          display: inline-block;
          font-size: 16px;
          text-align: center;
          color: #143D64;
          width: 100%;
      }
    }
      }
      }
    }

    
    }
  }
  