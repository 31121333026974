.parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; // Adjust as needed
}

.callNotification {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; // To stack items vertically

    .callForm{
        display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; // To stack items vertically
    background-color: #fff; // Set your desired form background color
    padding: 20px;
    border-radius: 10px;
    p {
        margin-bottom: 20px;
    }

    .callButtons {
        display: flex;
        justify-content: space-between;

        .acceptCallButton, .rejectCallButton {
            padding: 10px 20px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #f0f0f0; // Set your desired hover background color
            }
        }

        .acceptCallButton {
            background-color: #4caf50; // Set your desired accept button color
            color: #fff;
        }

        .rejectCallButton {
            margin-left: 10px;
            background-color: #f44336; // Set your desired reject button color
            color: #fff;
        }
    }
}
}
