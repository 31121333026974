.login{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 100vh;

    .login-card{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        .logo{
            display: flex;
            height: 100%;
            width: 50%;
            align-items: center;
            flex-direction: column;
            background-color: #F3F8FC;
            img{
                margin-top: 20px;
                width: 320px;
                height: 485px;
            }
            h3{
                font-size :18px;
                color: #143D64;
            }
        }
        .card{
            height: 100%;
            width: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            margin:100px;

            .card-header{
                margin-top: 20px;
                h3{
                    text-align: center;
                    color: #143D64;
                }
            }

            .card-body{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                form{
                    width:100%;
                .form-group{
                    width: 100%;
                    margin: 10px 0px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                     label{
                        color: #0A0A0A;
                        font-size: 19.2px;

                     }
                     .file-image{
                            .image{
                                display: flex;
                                flex-direction: column;
                                justify-content: space-evenly;
                                align-items: center;
                                background-color: #F3F8FC;
                                padding: 10px;
                                border-radius: 12px;
                                border: 2px dashed #143D64;
                                img{
                                    width: 100px;
                                    height: 100px;
                                }
                            }
                        .file{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-evenly;
                            align-items: center;
                            background-color: #F3F8FC;
                            padding: 10px;
                            border-radius: 12px;
                            border: 2px dashed #143D64;
                            img{
                                width: 100px;
                                height: 100px;
                            }

                            .overlay{
                                display: flex;
                            flex-direction: column;
                            justify-content: space-evenly;
                            align-items: center;
                            padding: 10px;
                            border-radius: 12px;
                            i{
                                margin-top: 15px;
                                color: #5F5F5F;
                            }
                            h3{
                                margin-top: 15px;
                                margin-bottom: 15px;
                                color: #5F5F5F;
                                font-size: 19.2px;
                                font-weight: 400;
                            }
                        }
                        }
                        &:hover{
                            cursor: pointer;
                        }
                    
                    }
                     .form-control{
                            width : 100%;
                            padding: 20px;
                            border: 1px solid #143D64;
                            flex-direction: row;
                            background-color: #F3F8FC;
                            border-radius: 12px;
                            display: flex;
                            input{
                                width: 95%;
                                border: none;
                                outline: none;
                                background-color: #F3F8FC;
                                font-size: 19.2px;
                                &::placeholder{
                                    color: black;
                                    font-weight: 400;
                                    font-size: 19.2px;
                                }
                            }
                        }
                }
                .submit-form{
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    margin-top: 30px;
                    .send{
                        border-radius: 30px;
                        width: 60%;
                        padding: 20px;
                        text-align: center;
                        background-color: rgba(194, 220, 241, 0.5);
                        margin-bottom: 20px;

                        button{
                            border: none;
                            background-color: rgba(194, 220, 241, 0);
                            font-size: 19.2px;
                            color: #143D64;
                            &:hover{
                                cursor: pointer;
                            }
                        }
                        &:hover{
                            cursor: pointer;
                        }
                    }
                    .link{
                        text-align: center;
                    }
                }
            }
            }

        }
    }
}

input::selection {
    background-color: #F3F8FC; /* Same as background color */
    color: black; /* Text color */
}