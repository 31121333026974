.col-9 {
  width : 100%;
  .right-side {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    input[type='checkbox'] {
      display: none;
    }

    .row {
      height: 100%;
      width: 100%;

      .col-8 {
        transition: all 0.5s;
        //top: 50px;
        position: relative;
        width: 100%;

        .message-send-show {
          height: 100%;
          border-right: 1px solid var(--border-color);
          margin-top: 1px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          //border-radius: 5px; /* Add border radius */
          //margin-bottom: 10px; /* Add margin to separate from FriendInfo */
          .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 20px 10px;
            //background-color: #017aff;
            // border-radius: 15px 15px 0 0;

            .image-name {
              display: flex;
              align-items: center;
              cursor: pointer;

              .image {
                position: relative;
                width: 40px;
                height: 40px;

                img {
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                }

                .active-icon {
                  position: absolute;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background-color: rgb(28, 212, 28);
                  right: 0;
                  bottom: 0;
                }
              }

              .name-date {

                span {
                  font-size: 12px;
                  color: var(--text-color);
                  margin-left: 10px;
                }
                h3 {
                  padding-left: 10px;
                  color: var(--text-color);
                }
              }
            }

            .icons {
              display: flex;
              justify-content: space-between;

              i {
                height: 35px;
                width: 35px;
                margin-left: 8px;
                border-radius: 24px;
                color: #143D64;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }

      }
    }
  }
}
  

