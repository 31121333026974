.container-big {
  border-radius: 5px;
  background-color: black;
  display: flex;
  height: 100%;
  justify-content: space-between;
  display: flex;
  .remote-side {
    margin-top: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    flex: 1; /* Take up remaining space */
    background-color: #F3F8FC;
    border-radius: 10px;
    display: flex;
    .buttons{
      z-index: 1;
      width : auto;
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;
      margin-top: 15%;
      margin-left: 8%;
      margin-right: 3%;
      .video-off{
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #888686;
        border-radius: 50%;
        padding: 12px;
        cursor: pointer;
      }
      .mute{
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #888686;
        border-radius: 50%;
        cursor: pointer;
        padding: 12px;

      }
      .hangup{
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F8000F;
        border-radius: 10px;
        padding: 12px;
        cursor: pointer;
      }
    
    }
    .in-call{
      z-index: 1;
      width :100%;
      height: 100%;
      display: flex;
      flex : 1;
      overflow: hidden;
      video {
        border-radius: 10px;
        max-width: 80%;
        max-height: 100%;
      }
      .if-remote-video-off{
        width : 80%;
        max-width: 80%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #5F5F5F;
        border-radius: 10px;
        img{
          width: 150px;
          height: 150px;
          border-radius: 50%;
          margin-bottom:10px;
        }
        h3{
          font-size: 20px;
          font-weight: bold;
          color: white;
        }
  }
    }
    .call-form{
      width : 100%;
      display: flex;
      height: 100%;
      border-radius: 10px;
      background-color: #F3F8FC;
      justify-content: space-evenly;
      flex-direction: column;
      .not-in-call{
        width : 92%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 5%;

        margin-bottom:10px;
        img{
          width: 150px;
          height: 150px;
          border-radius: 50%;
          margin-bottom:10px;
        }
        h3{
          font-size: 20px;
          font-weight: bold;
          color: #143D64;
        }
      }
      .call-form-body{
        width : 50%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        margin-left:22%;
        p{
          font-size: 20px;
          font-weight: bold;
          color: #143D64;
        }
        .hangup{
          width: auto;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #143D64;
          border-radius: 10px;
          padding: 12px;
          margin-left: 10px;
          cursor: pointer;
        }
        .has-not-called{
          width : 100%;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          .call{
            width: auto;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #00B200;
            border-radius: 10px;
            padding: 12px;
            margin-right: 10px;
            cursor: pointer;
            i{
              color: white;
            }
          }
          .cancel{
            width: auto;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #143D64;
            border-radius: 10px;
            padding: 12px;
            margin-left: 10px;
            cursor: pointer;
          }
          }
        }

    }
  }

  .local-side {
    width: 20%;
    height: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-left: 5px;
  .timer {
    height: 70%;
    margin-top:10px;
    .time-text{
      width : 100%;
      height: 10%;
      background-color: #5F5F5F;
      border-radius: 15px;
      padding: 15px;
      align-items: center;
      justify-content: center;
      display: flex;
    p {
      font-size: 20px;
      font-weight: bold;
      color: white;
    }
  }
  }

  .local-video {
    width: 100%;
    height: 30%;
    margin-bottom: 5px;
    display: flex;
    flex: 1;
    justify-content: center;
    video {
      border-radius: 10px;
      width: 100%;
      height: 100%;
    }
    .if-local-video-off{
      height: 100%;
      width: 80%;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #5F5F5F;
      border-radius: 12px;
    img{
      width :40%;
      height: 40%;
      border-radius: 50%;
    }
  }
  }
}
}
