// Forward.scss
.forward-container{
  height: 100%;
          border-right: 1px solid var(--border-color);
          margin-top: 1px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
.forward-header {
    h2 {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
  
  .friends {
    margin-bottom: 20px;
  
    .friend-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 5px;
  
      span {
        margin-right: 10px;
      }
    }
  }
  
  .choice {
    display: flex;
    justify-content: space-between;
    margin: 20px;
    button {
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
  
      &:hover {
        background-color: #ddd;
      }
    }
  }
}