.AddChoice {
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;

  .add-choice-container {
    background-color: white;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Box shadow for a subtle depth effect */

  .top {
    text-align: start;
    margin-bottom: 20px;

    h2 {
      font-size: 30px;
      font-weight: bold;
    }

    p {
      margin: 10px 0;
      font-size: 18px;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;

    button {
      padding: 8px 16px;
      border: 1px solid #143D64;
      border-radius: 10%;
      font-size: 16px;
      //transition: background-color 0.3s ease;
      width : 86px;
      height: 40px;

      &:first-child {
        background-color: white; /* Light blue color for the 'Add' button */
        color: #143D64;
        margin-right: 10px; /* Add margin between buttons */
      }

      &:last-child {
        background-color: #C2DCF1; /* White color for the 'Cancel' button */
        color: #143D64;
      }

      &:hover {
        background-color: #0056b3; /* Darker shade of blue on hover for the 'Add' button */
      }
    }
  }
}
}
