.profile-section {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  
    .profile-hov {
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      padding: 20px;
      width: 400px;
      text-align: center;
  
      .close{
        cursor: pointer;
        margin-left: 300px;
      }
      h2 {
        margin-bottom: 20px;
        font-size: 24px;
        color: #333;
      }
  
      form {
        display: flex;
        flex-direction: column;
        align-items: center;
  
        .profile-image {
          position: relative;
          width: 100px;
          height: 100px;
          margin-bottom: 20px;
  
          img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
            border: 2px solid #ddd;
          }
  
          .camera-icon {
            position: absolute;
            bottom: 0;
            right: 0;
            background: #fff;
            border-radius: 45%;
            width: 35px;
            height: 35px;
            padding: 5px;
            cursor: pointer;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            align-items: center;
            justify-content: center;
          }
        }
  
        div {
          margin-bottom: 15px;
          width: 100%;
  
          
          .tooltip {
            display: none;
            position: absolute;
            bottom: -20px;
            left: 0;
            background-color: #333;
            color: #fff;
            padding: 5px;
            border-radius: 3px;
            font-size: 12px;
            white-space: nowrap;
        }

        &:hover .tooltip {
            display: block;
        }
          label {
            display: block;
            margin-bottom: 5px;
            font-weight: bold;
            color: #555;
          }
  
          input[type='text'],
          input[type='email'] {
            width: 100%;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            font-size: 16px;
            color: #333;
  
            &:focus {
              border-color: #0088cc;
              outline: none;
            }
          }
  
          input[type='file'] {
            display: none;
          }
        }
  
        button {
          background: #0088cc;
          color: #fff;
          border: none;
          padding: 10px 20px;
          border-radius: 5px;
          font-size: 16px;
          cursor: pointer;
  
          &:hover {
            background: #0077bb;
          }
        }
      }
    }
  }
