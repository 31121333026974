.document-message {
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 10px;
    margin: 10px 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.document-display {
    display: flex;
    align-items: center;
    width: 100%;
}

.document-icon img {
    width: 40px;
    height: 40px;
    margin-right: 15px;
}

.document-info {
    flex-grow: 1;
}

.document-name {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    color: #333;
}

.document-size {
    font-size: 14px;
    color: #777;
}

.document-download a {
    background-color: #007bff;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
}

.document-download a:hover {
    background-color: #0056b3;
}

.message-show {
    height: 100%;
    padding: 10px;
    overflow-y: auto;
    background-image : url('../../imagelog/backgroundMessaging.png');

    .friend_connect {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        color: var(--text-color);
        margin-bottom: 12px;

        img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            min-width: 100px;
            min-height: 100px;
        }

        h3 {
            margin-top: 10px;
        }
    }

    .my-message, .fd-message  {

        position : relative;
        border-radius: 10px;
        display: flex;
        align-items: flex-start; // Aligns message content to the top for consistency
        height: auto;

        .image-message-time {
            flex: 0 0 40px; // Ensures this container takes up space even when no image is present
            height: 100%;
            display: flex;
            min-width: 40px; // Prevents shrinking
        height: 40px; // Consistent height
            padding-right: 0.2%;
            flex-direction: column;
            align-items: flex-end;
            img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 10px;
                min-width: 32px;
                min-height: 32px;
                margin-bottom: -10px;
            }

        }

        .message-time {
            flex: 1; // Takes the remaining space
            display: flex;
            flex-direction: column;
            align-items: flex-start; // Aligns message content to the top for consistency
            justify-content: space-between;
            padding-top: 5px;
            width: 100%;
            .fd-text {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                white-space: pre-wrap;
                white-space-collapse: preserve;
                text-wrap: wrap;
                word-break: break-word;
                .time-status {
                    margin-left: 5px;
                    margin-top: 10px;
                    align-items: center;
                    //aligne them horizontally
                    display: flex;
                    
                    .time{
                        font-size: 12px;
                        margin-left: 5px;
                        text-align: end;
                    padding: 2px 0px;
                    color: #727272d9;
                    font-size: 12px;
                    }
                    i{
                        color: #727272d9;
                        font-size: 17px;
                        margin-left: 5px;
                    }
                }
                .message-with-icon {
                    height: 100%;
                    padding: 5px;
                    overflow-y: auto;
                    width : 100%;

                    .message-text {
                        display: inline-block;
                        border-radius: 20px; // Rounded corners
                        padding: 16px 20px; // Adjust padding as needed
                        margin: 5px 0; // Adjust margin as needed
                        max-width: 70%; // Adjust max-width as needed
                        word-wrap: break-word; // Ensure long messages wrap within the bubble

                        p{
                            font-size: 14px;
                            margin: 0;

                        }
                    }
                    .edit-message {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border: 1px solid #999393;
                        border-radius: 5px;
                        padding: 5px;
                        margin-top: 5px;
                        
                        input {
                            flex: 1;
                            border: none;
                            padding: 5px;
                            border-radius: 5px;
                        }
                    
                        button {
                            background-color: #007bff;
                            color: #fff;
                            border: none;
                            padding: 5px 5px;
                            border-radius: 5px;
                            cursor: pointer;
                            margin-left: 5px;
                        }
                    }
                    
                    .MuiSvgIcon-root {
                        margin-left: 5px;
                        cursor: pointer;
                        padding-top: 12px;
                    }
                }

                img {

                    width: 30%;
                    height: 30%;
                    margin-right: 0px;
                    border-radius: 20px;
                    overflow: hidden;
                    object-fit: cover;
                    margin-left: 1%;
                    margin-top: 0.5%;

                }
                .options-menu-icon{
                    position: relative;
                    display: flex;
                    padding: 10px;
                    cursor: pointer;
                }
            }
            .options-menu {
                position: absolute;
                right: 5%;
                top: 50%;
                transform: translateY(-50%);
                background-color: var(--bg-color);
                border-radius: 10px;
                padding: 10px;
                z-index: 1;
                ul {
                    list-style: none;
                    padding: 0;
                    
                    li {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        padding: 10px 0; // Adjust as needed
            
                        p {
                            margin-left: 10px; // Adjust as needed for spacing between icon and text
                        }
            
                        // Adjust the icon style
                        svg {
                            //make the color of the icon same as the text color
                            color: var(--text-color);
                            width: 15px; // Set the width of the icon
                            height: 15px; // Set the height of the icon
                        }
                    }
                }
            }

        }

    }

    .typing-message {
        /* Styles for typing message */
        .friend_connect {
            /* Styles for the container of the typing message */
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
            color: var(--text-color);

            img {
                /* Styles for the image */
                width: 32px;
                height: 32px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 10px;
                margin-top: 10px;
                min-width: 32px;
                min-height: 32px;
                margin-bottom: -10px;
            }

            h3 {
                /* Styles for the heading */
                margin-top: 10px;
            }

            span {
                /* Styles for other elements inside the container */
                /* Add styles as needed */
            }
        }
    }
}



.displayed-image-container {
    position: fixed; // Fixed positioning
    top: 50%; // Center vertically
    left: 50%; // Center horizontally
    transform: translate(-50%, -50%); // Center the container
    z-index: 9999; // Ensure the container appears on top
    background-color: rgba(0, 0, 0, 0.8); // Semi-transparent background
    padding: 20px; // Adjust padding as needed
    border-radius: 10px; // Rounded corners
}

.displayed-image-container img {
    max-width: 100%; // Ensure image doesn't overflow container
    max-height: 100%; // Ensure image doesn't overflow container
    border-radius: 10px; // Rounded corners
}
.message-show .video-message {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 300px; /* Set your desired max width */
    background-color: #F0F0F0; /* Background color similar to the message box */
    border-radius: 10px; /* Rounded corners */
    overflow: hidden;
    margin: 10px 0;
}

.message-show .video-message video {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Match the border-radius of the container */
    display: block;
}
