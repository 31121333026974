// .register{
//     width: 100%;
//     height: 100vh;
//     background-color: azure;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     .cardd{
//         .card-header{
//             h3{
//                 text-align: center;
//                 color: #fff;
//             }
//         }
//         .card-body{
//             .form-group{
//                 .form-control{
//                     color: #fff;
//                 }
//                 &:last-child{
//                     margin-bottom: 0px;
//                 }
//                 span{
//                     text-align: center;
//                     a{
//                         color: #fff;
//                     }
//                 }
//                 .file-image{
//                     display: flex;
//                     .image{
//                         width: 50px;
//                         height: 50px;
//                         border-radius: 50%;
//                         border : 1px solid rgb(199, 195, 195);
//                         overflow: hidden;
//                         img{
//                             width: 100%;
//                             height: 100%;
//                             object-fit: cover;
//                         }
//                     }
//                     .file{
//                         display: flex;
//                         flex-direction: column;
//                         justify-content: center;
//                         align-items: center;
//                         .form-control{
//                             display: none;
//                         }
//                         label{
//                             background-color: #0a0e1585;
//                             padding: 8px;
//                             margin-bottom: 0px;
//                             width: 120px;
//                             text-align: center;
//                             border-radius: 50px;
//                             margin-left: 10px;
//                             cursor: pointer;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

//  .logo1{

//     height: 520px;
    
//  }
//  ._card{
//     display: flex;
//  }

//  .logo2{
//     height: 300px;
//     width: 100%;
//  }

.register{
    width: 100%;
    height: 100vh;
    background-color:#f4f8fc;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo {
        text-align: center;
        width: 40px; /* Adjust width as needed */
        height: auto; /* Maintain aspect ratio */       
    }
    .card{
        .card-header{
            h1{
                text-align: center;
                color: #000;
                margin-bottom: 20px;
                
            }
        }
        .card-body{
            .form-group{
                .form-control{
                    color: #000000;
                    border-radius: 5px;
                }
                &:last-child{
                    margin-bottom: 0px;
                }
                span{
                    text-align: center;
                    a{
                        color: #4d73dd;
                         
                    }
                }
                input::placeholder {
                    color: #000; /* Change to the color you prefer */
                }
                input {
                    border: 2px solid #ccc;
                    padding: 10px;
                    border-radius: 5px;
                    transition: border-color 0.3s ease;
                }
                input:focus {
                    border-color: #4d73dd;
                    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
                }
                .file-image{
                    display: flex;
                    .image{
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        border : 2px solid #ccc;
                        overflow: hidden;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .file{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        .form-control{
                            display: none;
                        }
                        label{
                            background-color: #fff;
                            padding: 8px;
                            margin-bottom: 0px;
                            width: 120px;
                            text-align: center;
                            border: 2px solid #4d73dd;
                            border-radius: 50px;
                            margin-left: 10px;
                            cursor: pointer;
                        }

                        label:focus {
                            border-color: #4d73dd;
                            box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
                        }
                    }
                }
            }
        }
    }
}
